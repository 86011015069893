
import {
  Component, Prop, Ref, Vue, Watch,
} from 'vue-property-decorator'
import VueApexCharts from 'vue-apexcharts'
import Widget from '@/components/Widget/Widget.vue'
import FinancialPlan from '@/models/FinancialPlan'
import MediaPlan from '@/models/MediaPlan'
// @ts-ignore
import { VueFunnelGraph } from 'vue-funnel-graph-js'
import Invoice from '@/models/Invoice'
import DataTable from '@/components/DataTable/index.vue'
import Opportunity from '@/models/Opportunity'
import numeral from 'numeral'
import settings from './plan-widget-settings'

@Component({
  components: {
    Widget,
    VueApexCharts,
    VueFunnelGraph,
    DataTable,
  },
})
export default class PlanWidget extends Vue {
  @Ref() readonly containerRef!: HTMLDivElement

  @Prop({ default: 'Agency Revenue distribution' })
  public title!: string | null

  @Prop({ required: true })
  public widgetKey!: string

  @Prop()
  public year!: number

  @Prop({ default: null })
  public region!: string | null

  @Prop({ default: false })
  public show_year_note!: boolean

  @Prop({ default: null })
  public agency!: string | null

  @Prop({ required: true })
  public type!: string

  @Prop({ required: true })
  public dimensions!: string[]

  @Prop()
  public dynamic_options!: any

  @Prop({ required: true })
  public metrics!: string[]

  @Prop({ default: () => ({}) })
  public filters!: any

  @Prop({ default: () => ({}) })
  public order!: any

  @Prop({ default: () => ({}) })
  public query!: any

  @Prop({ default: () => ({}) })
  public config!: any

  @Prop({ required: true })
  public source!: string

  @Prop({ default: false })
  public capitalizeLabels!: boolean

  @Prop({ default: false })
  public disableAnimations!: boolean

  @Prop({ default: '' })
  public snapshot_id!: string

  @Watch('query')
  public onQueryChange(val: any, old: any) {
    if (JSON.stringify(old) !== JSON.stringify(val)) {
      this.updateData()
    }
  }

  public fmtNumeral(value: number) {
    return numeral(value)
  }

  public calculateDiff(value: number, lastYear: number) {
    if (lastYear == 0) {
      return 0
    }
    return (value - lastYear) / lastYear
  }

  public get unsupported_filters() {
    const ret: string[] = []

    if (this.source === 'financial_plan') {
      this.query.forEach((q: string) => {
        if (q.startsWith('agency')) {
          if (!ret.includes('Agency')) ret.push('Agency')
        }
        if (q.startsWith('advertiser')) {
          if (!ret.includes('Advertiser')) ret.push('Advertiser')
        }
        if (q.startsWith('sr:')) {
          if (!ret.includes('Sales Rep')) ret.push('Sales Rep')
        }
        if (q.startsWith('sm:')) {
          if (!ret.includes('Sales Manager')) ret.push('Sales Manager')
        }
        if (q.startsWith('deal_type:')) {
          if (!ret.includes('Deal Type')) ret.push('Deal Type')
        }
      })
    }

    return ret
  }

  @Watch('local_metrics')
  public onMetricsChange(val: any, old: any) {
    if (!this.ready) return
    this.updateData()
  }

  @Watch('local_filters.dynamic_filter')
  public onFiltersChange(val: any, old: any) {
    if (!this.ready) return
    this.updateData()
  }

  public get container() {
    if (!this.containerRef || !this.containerRef.clientWidth) {
      return {
        width: 800,
        height: 300,
      }
    }

    return {
      width: this.containerRef.clientWidth,
      height: 300,
    }
  }

  public report_data: any = []

  public loading: boolean = false

  public ready: boolean = false

  public total: number = 0

  public series: any[] = []

  public series_raw: number[] = []

  public labels: string[] = []

  public sub_labels: string[] = []

  public colors: string[] = []

  public local_metrics: string[] = []

  public local_filters: any = {}

  public series_ids: string[] = []

  public get cardTitle() {
    return this.title?.replaceAll('%YEAR%', this.year.toString())
  }

  public get chartSettings() {
    // @ts-ignore
    return settings[this.type]
  }

  public get options() {
    const options = this.chartSettings.base_options

    if (this.disableAnimations !== false) {
      options.chart.animations = {
        enabled: false,
      }
    }

    if (this.type === 'bar_stacked' || this.type === 'funnel') {
      options.xaxis.categories = this.labels
    } else {
      options.labels = this.labels
    }

    if (this.colors && this.colors.length > 0) {
      options.colors = this.colors
    }
    if (this.chartSettings.dataLabelFormatter) {
      options.dataLabels.formatter = (value: number, opt: any) =>
        this.chartSettings.dataLabelFormatter(value, this.labels, this.series_raw, this.total, opt)
    }
    if (this.chartSettings.tooltipFormatter) {
      options.tooltip.y.formatter = (value: number, opt: any) =>
        this.chartSettings.tooltipFormatter(value, this.labels, this.series_raw, this.total, opt)
    }
    if (this.chartSettings.legendFormatter) {
      options.legend.formatter = (value: number, opt: any) =>
        this.chartSettings.legendFormatter(value, this.labels, this.series_raw, this.total, opt)
    }

    if (this.chartSettings.plotFormatter) {
      options.plotOptions.radialBar.dataLabels.value.formatter = (value: number, opt: any) =>
        this.chartSettings.plotFormatter(value, this.labels, this.series_raw, this.total, opt)
    }

    if (this.config && this.config.click_key) {
      options.chart.events = {
        click: (_: any, __: any, config: any) => {
          let filter = `${this.config.click_key}:`
          let name = `${this.config.click_key}:`

          if (this.type === 'treemap') {
            filter += this.series_ids[config.dataPointIndex]
            name += this.labels[config.dataPointIndex]
          } else {
            filter += this.series_ids[config.seriesIndex]
            name += this.series[config.seriesIndex].name
          }

          this.$emit('addFilter', { filter, name })
        },
      }
    }

    return options
  }

  public mapData() {
    if (this.type === 'table') {
      // sort by the first dimension
      this.report_data = this.report_data.sort((a: any, b: any) => {
        if (a[this.dimensions[0]] < b[this.dimensions[0]]) {
          return -1
        }
        if (a[this.dimensions[0]] > b[this.dimensions[0]]) {
          return 1
        }
        return 0
      })
      return
    }
    if (this.type === 'custom') {
      return
    }
    const ret = this.chartSettings.mapper(this.report_data, {
      dimensions: this.dimensions,
      metrics: this.local_metrics,
      capitalizeLabels: this.capitalizeLabels,
      config: this.config,
    })

    if (ret.colors) {
      this.colors = ret.colors
    }
    this.total = ret.total
    this.series = ret.series
    this.series_raw = ret.series_raw

    if (ret.series_ids) {
      this.series_ids = ret.series_ids
    } else {
      this.series_ids = []
    }
    if (ret.labels) {
      this.labels = ret.labels
    }
    if (ret.sub_labels) {
      this.sub_labels = ret.sub_labels
    }
  }

  public updateData() {
    this.loading = true
    this.resetData()
    let query = [...this.query]

    query.push(`year:${this.year}`)
    if (this.region) {
      query.push(`region:${this.region}`)
    }
    if (this.agency) {
      query.push(`agency_id:${this.agency}`)
    }

    query.push('scope:agency')

    if (this.source === 'financial_plan') {
      return FinancialPlan.getPlanDashboard({
        dimensions: this.dimensions,
        metrics: this.local_metrics,
        filters: {
          year: this.year,
          ...this.local_filters,
        },
        order: this.order,
        query,
        snapshot: this.snapshot_id,
      }).then((res: any) => {
        this.report_data = res.data.result.sort(
          (a: any, b: any) => b[this.local_metrics[0]] - a[this.local_metrics[0]],
        )

        this.mapData()
        this.loading = false
        this.ready = true
      })
    }
    if (this.source === 'opportunity') {
      return Opportunity.buildQuery({
        dimensions: this.dimensions,
        metrics: this.local_metrics,
        filters: {
          year: this.year,
          ...this.local_filters,
        },
        order: this.order,
        query,
        snapshot: this.snapshot_id,
      }).then((res: any) => {
        if (this.type !== 'funnel') {
          this.report_data = res.data.result.sort(
            (a: any, b: any) => b[this.local_metrics[0]] - a[this.local_metrics[0]],
          )
        } else {
          this.report_data = res.data.result
        }
        this.mapData()
        this.loading = false
        this.ready = true
      })
    }

    if (this.source === 'opportunity-week-card') {
      return Opportunity.weeklyCard({
        dimensions: this.dimensions,
        metrics: this.local_metrics,
        filters: {
          year: this.year,
          ...this.local_filters,
        },
        order: this.order,
        query,
        snapshot: this.snapshot_id,
      }).then((res: any) => {
        this.report_data = res.data.result
        this.loading = false
        this.ready = true
      })
    }

    if (this.source === 'week-pipeline') {
      return Opportunity.weekPipeline({
        dimensions: this.dimensions,
        metrics: this.local_metrics,
        filters: {
          year: this.year,
          ...this.local_filters,
        },
        order: this.order,
        query,
        snapshot: this.snapshot_id,
      }).then((res: any) => {
        this.report_data = res.data.result
        this.loading = false
        this.ready = true
      })
    }
    if (this.source === 'opportunity-advanced') {
      return Opportunity.buildAdvancedQuery({
        dimensions: this.dimensions,
        metrics: this.local_metrics,
        filters: {
          year: this.year,
          ...this.local_filters,
        },
        order: this.order,
        query,
        snapshot: this.snapshot_id,
      }).then((res: any) => {
        if (this.type !== 'funnel' && this.type !== 'bar_stacked') {
          this.report_data = res.data.result.sort(
            (a: any, b: any) => b[this.local_metrics[0]] - a[this.local_metrics[0]],
          )
        } else {
          this.report_data = res.data.result
        }
        this.mapData()
        this.loading = false
        this.ready = true
      })
    }
    if (this.source === 'media_plans') {
      return MediaPlan.buildQuery({
        dimensions: this.dimensions,
        metrics: this.local_metrics,
        filters: {
          year: this.year,
          ...this.local_filters,
        },
        query,
        snapshot: this.snapshot_id,
      }).then((res: any) => {
        this.report_data = res.data.result.sort(
          (a: any, b: any) => b[this.local_metrics[0]] - a[this.local_metrics[0]],
        )
        this.mapData()
        this.loading = false
        this.ready = true
      })
    }

    if (this.source === 'invoices') {
      return Invoice.buildQuery({
        dimensions: this.dimensions,
        metrics: this.local_metrics,
        filters: {
          year: this.year,
          ...this.local_filters,
        },
        query,
        snapshot: this.snapshot_id,
      }).then((res: any) => {
        this.report_data = res.data.result.sort(
          (a: any, b: any) => b[this.local_metrics[0]] - a[this.local_metrics[0]],
        )
        this.mapData()
        this.loading = false
        this.ready = true
      })
    }
  }

  public created() {
    Vue.set(this, 'local_metrics', this.metrics)
    Vue.set(this, 'local_filters', this.filters)
    this.updateData()
  }

  public getFieldFoot(key: string) {
    const field_index = this.config.fields.findIndex((f: any) => f.key === key)
    const field = this.config.fields[field_index]

    if (field_index === 0) {
      return 'Total'
    }
    if (!field || !field.total || field.total === 'none') {
      return ''
    }
    let ret = this.report_data.reduce((acc: number, row: any) => acc + Number(row[key]), 0)

    return numeral(ret).format('$0,0.00a')
  }

  public getFieldTotal(key: string) {
    return this.report_data.reduce((acc: number, row: any) => acc + Number(row[key]), 0)
  }

  private resetData() {
    this.colors = []
    this.total = 0
    this.series = []
    this.series_raw = []
    this.labels = []
    this.sub_labels = []
  }
}
