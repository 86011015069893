import { buildColorPool } from '@/models/Util'
import numeral from 'numeral'
import { startCase } from 'lodash'
import moment from 'moment'

const normalizeLabel = (name: string, settings: any) => {
  if (!name) return 'N/A'
  if (settings.capitalizeLabels) {
    name = startCase(name)
  }
  return name
}

export default {
  funnel: {
    base_options: {
      chart: {
        type: 'bar',
        height: '100',
        width: '200',
        zoom: {
          enabled: false,
        },
        animations: {
          enabled: true,
        },
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        dropShadow: {
          enabled: true,
        },
      },
      tooltip: {
        enabled: true,
        y: {},
      },
      legend: {
        position: 'bottom',
        show: true,
        showForSingleSeries: true,
      },
      xaxis: {},
      plotOptions: {
        bar: {
          borderRadius: 0,
          distributed: true,
          horizontal: true,
          barHeight: '80%',
          isFunnel: true,
        },
      },
    },
    dataLabelFormatter: (
      value: number,
      labels: string[],
      series: number[],
      __: number,
      opt: any,
    ) => {
      const raw_value = series[opt.dataPointIndex]
      const label = labels[opt.dataPointIndex]
      return [`${label}: ${numeral(raw_value).format('$0.00a')}`]
    },
    tooltipFormatter: (
      value: number,
      labels: string[],
      series: number[],
      total: number,
      opt: any,
    ) => {
      const raw_value = series[opt.dataPointIndex]
      return [
        `${numeral(raw_value).format('$0.00a')}`,
        ` ${numeral(raw_value / total).format('0.00%')}`,
      ]
    },
    mapper: (data: any[], settings: any) => {
      const data_series: any[] = []
      const series_raw: any[] = []
      const series: number[] = []
      const labels: string[] = []
      const sub_labels: string[] = []
      const colors: any[] = []
      let total = 0

      // data = data.sort((a: any, b: any) => {
      //   if (a[settings.metrics[0]] < b[settings.metrics[0]]) {
      //     return 1
      //   }
      //   if (a[settings.metrics[0]] > b[settings.metrics[0]]) {
      //     return -1
      //   }
      //   return 0
      // })

      const { dimensions, metrics, config } = settings

      const color_pool = buildColorPool(data.length, true)

      // Init Series
      let count = 0
      const total_count = data.length
      data.forEach((item: any, idx: number) => {
        const val = Number(item[metrics[0]])
        total += val
        let label = normalizeLabel(item[dimensions[0]], settings)
        labels.push(label)
        colors.push(color_pool(count++))
        series_raw.push(Number(val.toFixed(2)))
        data_series.push(total_count - count + 1)
      })

      return {
        series_raw,
        series: [
          {
            name: 'Amount',
            data: data_series,
          },
        ],
        labels,
        sub_labels,
        colors,
        total,
      }
    },
  },
  pie: {
    base_options: {
      chart: {
        height: 350,
        type: 'pie',
        zoom: {
          enabled: false,
        },
        animations: {
          enabled: true,
        },
      },
      dataLabels: {},
      labels: [],
      tooltip: {
        enabled: true,
        y: {},
      },
      legend: {
        show: false,
        position: 'bottom',
      },
    },
    dataLabelFormatter: (
      value: number,
      labels: string[],
      series: number[],
      __: number,
      opt: any,
    ) => [`${numeral(value).format('$0.00a')}`],
    tooltipFormatter: (value: number, labels: string[], series: number[], __: number, opt: any) => {
      let total = series.reduce((acc: number, item: any) => (acc += item[opt.dataPointIndex]), 0)
      return [`${numeral(value).format('$0.00a')}`, ` ${numeral(value / total).format('0.00%')}`]
    },
    mapper: (data: any[], settings: any) => {
      const series: number[] = []
      const labels: string[] = []
      const colors: string[] = []
      let total = 0

      const { dimensions, metrics } = settings

      const color_pool = buildColorPool(data.length)

      data.forEach((item: any, idx: number) => {
        const val = Number(item[metrics[0]])
        total += val
        series.push(val)
        labels.push(normalizeLabel(item[dimensions[0]], settings))
        colors.push(color_pool(idx))
      })

      return {
        series_raw: series,
        series,
        labels,
        colors,
        total,
      }
    },
  },
  treemap: {
    base_options: {
      chart: {
        type: 'treemap',
        toolbar: false,
        zoom: {
          enabled: false,
        },
        animations: {
          enabled: true,
        },
        events: {},
      },
      dataLabels: {},
      labels: [],
      tooltip: {
        enabled: true,
        y: {},
      },
      legend: {
        show: false,
      },
      plotOptions: {
        treemap: {
          distributed: true,
          enableShades: false,
          dataLabels: {
            format: 'scale',
          },
        },
      },
    },
    dataLabelFormatter: (_: number, labels: string[], series: number[], __: number, opt: any) => [
      `${labels[opt.dataPointIndex]}`,
      `${numeral(series[opt.dataPointIndex]).format('$0.00a')}`,
    ],
    tooltipFormatter: (value: number, _: string[], __: number[], total: number, ___: any) => [
      `${numeral(value).format('$0.00a')}`,
      ` ${numeral(value / total).format('0.00%')}`,
    ],
    mapper: (data: any[], settings: any) => {
      const series: number[] = []
      const data_series: any[] = []
      const labels: any[] = []
      const colors: string[] = []
      const series_ids: string[] = []
      let total = 0

      const { dimensions, metrics } = settings

      const color_pool = buildColorPool(data.length)

      data.forEach((item: any, idx: number) => {
        const val = Number(item[metrics[0]])
        if (val === 0) {
          return
        }
        total += val
        series.push(val)
        const label = normalizeLabel(item[dimensions[0]], settings)
        data_series.push({
          x: label,
          y: val,
        })
        labels.push(label)
        colors.push(color_pool(idx))

        if (item[dimensions[1]]) {
          series_ids.push(item[dimensions[1]])
        }
      })

      return {
        series_raw: series,
        series: [
          {
            data: data_series,
          },
        ],
        labels,
        colors,
        total,
        series_ids,
      }
    },
  },
  bar_stacked: {
    base_options: {
      chart: {
        type: 'bar',
        toolbar: false,
        stacked: true,
        zoom: {
          enabled: false,
        },
        animations: {
          enabled: true,
        },
      },
      dataLabels: {},
      yaxis: {
        labels: {
          show: true,
          formatter: (value: any) => `${numeral(value).format('$0.00a')}`,
        },
      },
      labels: [],
      tooltip: {
        enabled: true,
        y: {},
      },
      xaxis: {
        categories: [],
      },
      plotOptions: {
        bar: {
          dataLabels: {
            total: {
              enabled: true,
              style: {
                fontSize: '13px',
                fontWeight: 900,
                color: '#000',
              },
            },
          },
        },
      },
      legend: {
        show: true,
        position: 'bottom',
        horizontalAlign: 'right',
        showForSingleSeries: true,
      },
    },
    dataLabelFormatter: (
      value: number,
      labels: string[],
      series: number[],
      __: number,
      opt: any,
    ) => [`${numeral(value).format('$0.00a')}`],
    tooltipFormatter: (value: number, _: string[], series: number[], __: number, opt: any) => {
      let total = opt.series.reduce(
        (acc: number, item: any) => (acc += item[opt.dataPointIndex]),
        0,
      )
      return [`${numeral(value).format('$0.00a')}`, ` ${numeral(value / total).format('0.00%')}`]
    },
    mapper: (data: any[], settings: any) => {
      const series: number[] = []
      const data_series: any[] = []
      const labels: any[] = []
      const colors: string[] = []
      const series_ids: string[] = []
      let total = 0

      const { dimensions, metrics, config } = settings

      const groups: string[] = []

      let x_series: string[] = []

      // Init Series
      data.forEach((item: any, idx: number) => {
        const label = normalizeLabel(item[dimensions[1]], settings)
        const group_index = groups.indexOf(label)
        if (group_index === -1) {
          groups.push(label)
          data_series.push({ name: label, data: [] })
        }

        const x_label = normalizeLabel(item[dimensions[0]], settings)
        if (x_series.indexOf(x_label) === -1) {
          x_series.push(x_label)
        }
      })

      const color_pool = buildColorPool(groups.length)

      data_series.forEach((item: any, idx: number) => {
        x_series.forEach(() => {
          item.data.push(0)
        })
        colors.push(color_pool(idx))
      })

      if (config.date_dimension_format) {
        x_series = x_series.sort(
          (a, b) =>
            moment(a, config.date_dimension_format).unix()
            - moment(b, config.date_dimension_format).unix(),
        )
      }

      data.forEach((item: any, idx: number) => {
        const val = Number(item[metrics[0]])
        total += val
        series.push(val)
        const label = normalizeLabel(item[dimensions[0]], settings)
        const group_label = normalizeLabel(item[dimensions[1]], settings)
        const group_index = groups.indexOf(group_label)
        const x_index = x_series.indexOf(label)

        data_series[group_index].data[x_index] += val

        if (['product', 'region', 'deal_type'].includes(dimensions[1])) {
          series_ids[group_index] = item[dimensions[1]].toLowerCase().replaceAll(' ', '_')
        } else if (dimensions[2]) {
          series_ids[group_index] = item[dimensions[2]]
        }
      })
      return {
        series_raw: series,
        series: data_series,
        labels: x_series,
        series_ids,
        colors,
        total,
      }
    },
  },
  radialBar: {
    base_options: {
      chart: {
        type: 'radialBar',
        offsetY: -20,
        sparkline: {
          enabled: true,
        },
        zoom: {
          enabled: false,
        },
        animations: {
          enabled: true,
        },
      },
      dataLabels: {},
      labels: [],
      tooltip: {
        enabled: true,
        y: {},
      },
      plotOptions: {
        radialBar: {
          offsetY: 0,
          startAngle: 0,
          endAngle: 270,
          hollow: {
            margin: 5,
            size: '30%',
            background: 'transparent',
            image: undefined,
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              show: false,
            },
          },
        },
      },
      legend: {
        show: true,
        floating: true,
        fontSize: '16px',
        position: 'left',
        offsetX: 0,
        offsetY: 5,
        showForSingleSeries: true,
        labels: {
          useSeriesColors: true,
        },
        markers: {
          size: 0,
        },
        itemMargin: {
          vertical: 3,
        },
      },
    },
    legendFormatter: (_: number, labels: string[], series: number[], __: number, opt: any) => [
      `${labels[opt.seriesIndex]}`,
      `${numeral(series[opt.seriesIndex]).format('$0.00a')}`,
    ],
    tooltipFormatter: (value: number, _: string[], series: number[], total: number, opt: any) => [
      `${numeral(series[opt.seriesIndex]).format('$0.00a')}`,
      ` (${numeral(value / 100).format('0.00%')})`,
    ],
    mapper: (data: any[], settings: any) => {
      const series: number[] = []
      const series_raw: number[] = []
      const labels: string[] = []
      const colors: string[] = []
      let total = 0

      const { dimensions, metrics } = settings

      const color_pool = buildColorPool(data.length)

      total = data.reduce((acc: number, item: any) => (acc += Number(item[metrics[0]])), 0)

      data.forEach((item: any, idx: number) => {
        const val = Number(item[metrics[0]])
        const target = Number(item[metrics[1]])
        series.push((val / target) * 100)
        series_raw.push(val)
        labels.push(normalizeLabel(item[dimensions[0]], settings))
        colors.push(color_pool(idx))
      })

      return {
        series,
        series_raw,
        labels,
        colors,
        total,
      }
    },
  },
  gauge: {
    base_options: {
      chart: {
        type: 'radialBar',
        offsetY: -20,
        sparkline: {
          enabled: true,
        },
        zoom: {
          enabled: false,
        },
        animations: {
          enabled: true,
        },
      },
      dataLabels: {
        value: {
          fontSize: '30px',
          show: true,
        },
        style: {
          colors: ['#000000'],
        },
      },
      fill: {
        // type: 'gradient',
        // gradient: {
        //   shade: 'dark',
        //   shadeIntensity: 0.5,
        //   inverseColors: false,
        //   opacityFrom: 1,
        //   opacityTo: 1,
        //   type: 'horizontal',
        //   gradientToColors: ['#323E48'],
        //   stops: [0, 100],
        // },
        type: 'image',
        image: {
          src: ['/images/gradient_gauge_final.png'],
          width: 3812,
        },
      },
      stroke: {
        lineCap: 'butt',
      },
      labels: [],
      tooltip: {
        enabled: true,
        y: {
          title: {
            formatter: (seriesName: any) => '',
          },
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          hollow: {
            margin: 5,
            size: '30%',
            background: 'transparent',
          },
          dataLabels: {
            name: {
              show: true,
              color: '#29323a',
            },
            value: {
              show: true,
            },
          },
        },
      },
      legend: {
        show: false,
        floating: true,
        fontSize: '16px',
        position: 'left',
        offsetX: 0,
        offsetY: 5,
        labels: {
          useSeriesColors: true,
        },
        markers: {
          size: 0,
        },
        itemMargin: {
          vertical: 3,
        },
      },
    },
    plotFormatter: (value: number, labels: string[], series: number[], total: number, opt: any) => [
      // `${numeral(series.reduce((acc: number, item: number) => (acc += item), 0)).format('$0.00a')}`,
      // 'vs',
      // `${numeral(total).format('$0.00a')}`,
    ],
    tooltipFormatter: (value: number, _: string[], series: number[], total: number, opt: any) => [
      `${numeral(series[opt.seriesIndex]).format('$0.00a')}`,
      ` (${numeral(value / 100).format('0.00%')})`,
    ],
    mapper: (data: any[], settings: any) => {
      const series: number[] = []
      const series_raw: number[] = []
      let labels: string[] = []
      const colors: string[] = []
      let total = 0

      const { dimensions, metrics, config } = settings

      // const color_pool = buildColorPool(5)

      total = data.reduce((acc: number, item: any) => (acc += Number(item[metrics[1]])), 0)

      data.forEach((item: any, idx: number) => {
        const val = Number(item[metrics[0]])
        const target = Number(item[metrics[1]])

        series.push((val / target) * 100)

        series_raw.push(val)
        labels.push(normalizeLabel(item[dimensions[0]], settings))
        colors.push('#323E48')
      })

      // ['#ff5135', '#313d49']

      if (config.set_label_to_percentage) {
        labels = [
          numeral(Number(data[0][metrics[0]]) / Number(data[0][metrics[1]])).format('0.00%'),
        ]
      }

      return {
        series,
        series_raw,
        labels,
        colors,
        total,
      }
    },
  },
}
