import numeral from 'numeral'

const gauge_custom_data = (data: any, target: string, period: string) => {
  let label = 'Sold'
  if (target != 'sold') {
    label = 'Pipeline'
  }
  // Sold Logic
  const sold = numeral(data[0][`goal_${target}`]).format('$0.00a')
  const last_year_sold = numeral(data[0][`last_year_goal_${target}`]).format('$0.00a')
  let last_year_sold_percentage: any = data[0][`last_year_goal_${target}`] == 0
    ? 1
    : (data[0][`goal_${target}`] - data[0][`last_year_goal_${target}`])
        / data[0][`last_year_goal_${target}`]
  const sold_icon = last_year_sold_percentage > 0 ? '▴' : '▾'
  const sold_color = last_year_sold_percentage > 0 ? 'text-success' : 'text-danger'
  last_year_sold_percentage = numeral(last_year_sold_percentage).format('0%')

  // Goal Logic
  const goal = numeral(data[0].goal).format('$0.00a')
  const last_year_goal = numeral(data[0].last_year_goal).format('$0.00a')
  let last_year_goal_percentage: any = data[0].last_year_goal == 0
    ? 1
    : (data[0].goal - data[0].last_year_goal) / data[0].last_year_goal
  const goal_icon = last_year_goal_percentage > 0 ? '▴' : '▾'
  const goal_color = last_year_goal_percentage > 0 ? 'text-success' : 'text-danger'
  last_year_goal_percentage = numeral(last_year_goal_percentage).format('0%')

  return `<div class="row text-center">
    <div class="col">
      <h6>${label}</h6>
      <h4>${sold}</h4>
      <div class="${sold_color}" style="font-size:small;">${sold_icon} ${last_year_sold_percentage}</div>
      <div class="text-muted">*Last ${period}: ${last_year_sold}</div>
    </div>
    <div class="col">
      <h6>Plan</h6>
      <h4>${goal}</h4>
      <div class="${goal_color}" style="font-size:small;">${goal_icon} ${last_year_goal_percentage}</div>
      <div class="text-muted">*Last ${period}: ${last_year_goal}</div>
    </div>
  </div>
  `
}

export default [
  {
    title: 'Sold to Plan %YEAR%',
    key: 'sold-to-goal-year',
    source: 'financial_plan',
    type: 'gauge',
    size: 4,
    dimensions: ['year'],
    capitalize_labels: true,
    metrics: ['goal_sold', 'goal', 'last_year_goal', 'last_year_goal_sold'],
    filters: {
      type: 'finance_goal',
    },
    config: {
      set_label_to_percentage: true,
      custom_data: (data: any) => gauge_custom_data(data, 'sold', 'year'),
    },
  },
  {
    title: 'Q1 Sold to Plan',
    key: 'sold-to-goal-q1',
    source: 'financial_plan',
    type: 'gauge',
    size: 2,
    dimensions: ['quarter'],
    capitalize_labels: true,
    metrics: ['goal_sold', 'goal', 'last_year_goal', 'last_year_goal_sold'],
    filters: {
      quarter: 1,
      type: 'finance_goal',
    },
    config: {
      set_label_to_percentage: true,
      custom_data: (data: any) => gauge_custom_data(data, 'sold', 'Q1'),
    },
  },
  {
    title: 'Q2 Sold to Plan',
    key: 'sold-to-goal-q2',
    source: 'financial_plan',
    type: 'gauge',
    size: 2,
    dimensions: ['quarter'],
    capitalize_labels: true,
    metrics: ['goal_sold', 'goal', 'last_year_goal', 'last_year_goal_sold'],
    filters: {
      quarter: 2,
      type: 'finance_goal',
    },
    config: {
      set_label_to_percentage: true,
      custom_data: (data: any) => gauge_custom_data(data, 'sold', 'Q2'),
    },
  },
  {
    title: 'Q3 Sold to Plan',
    key: 'sold-to-goal-q3',
    source: 'financial_plan',
    type: 'gauge',
    size: 2,
    dimensions: ['quarter'],
    capitalize_labels: true,
    metrics: ['goal_sold', 'goal', 'last_year_goal', 'last_year_goal_sold'],
    filters: {
      quarter: 3,
      type: 'finance_goal',
    },
    config: {
      set_label_to_percentage: true,
      custom_data: (data: any) => gauge_custom_data(data, 'sold', 'Q3'),
    },
  },
  {
    title: 'Q4 Sold to Plan',
    key: 'sold-to-goal-q4',
    source: 'financial_plan',
    type: 'gauge',
    size: 2,
    dimensions: ['quarter'],
    capitalize_labels: true,
    metrics: ['goal_sold', 'goal', 'last_year_goal', 'last_year_goal_sold'],
    filters: {
      quarter: 4,
      type: 'finance_goal',
    },
    config: {
      set_label_to_percentage: true,
      custom_data: (data: any) => gauge_custom_data(data, 'sold', 'Q4'),
    },
  },
  {
    title: 'Pipeline to Plan %YEAR%',
    key: 'revenue-to-goal-year',
    source: 'financial_plan',
    type: 'gauge',
    size: 4,
    dimensions: ['year'],
    capitalize_labels: true,
    metrics: ['goal_pipeline', 'goal', 'last_year_goal', 'last_year_goal_pipeline'],
    filters: {
      type: 'finance_goal',
    },
    config: {
      set_label_to_percentage: true,
      custom_data: (data: any) => gauge_custom_data(data, 'pipeline', 'year'),
    },
  },
  {
    title: 'Q1 Pipeline to Plan',
    key: 'plan-to-goal-q1',
    source: 'financial_plan',
    type: 'gauge',
    size: 2,
    dimensions: ['quarter'],
    capitalize_labels: true,
    metrics: ['goal_pipeline', 'goal', 'last_year_goal', 'last_year_goal_pipeline'],
    filters: {
      quarter: 1,
      type: 'finance_goal',
    },
    config: {
      set_label_to_percentage: true,
      custom_data: (data: any) => gauge_custom_data(data, 'pipeline', 'Q1'),
    },
  },
  {
    title: 'Q2 Pipeline to Plan',
    key: 'plan-to-goal-q2',
    source: 'financial_plan',
    type: 'gauge',
    size: 2,
    dimensions: ['quarter'],
    capitalize_labels: true,
    metrics: ['goal_pipeline', 'goal', 'last_year_goal', 'last_year_goal_pipeline'],
    filters: {
      quarter: 2,
      type: 'finance_goal',
    },
    config: {
      set_label_to_percentage: true,
      custom_data: (data: any) => gauge_custom_data(data, 'pipeline', 'Q2'),
    },
  },
  {
    title: 'Q3 Pipeline to Plan',
    key: 'plan-to-goal-q3',
    source: 'financial_plan',
    type: 'gauge',
    size: 2,
    dimensions: ['quarter'],
    capitalize_labels: true,
    metrics: ['goal_pipeline', 'goal', 'last_year_goal', 'last_year_goal_pipeline'],
    filters: {
      quarter: 3,
      type: 'finance_goal',
    },
    config: {
      set_label_to_percentage: true,
      custom_data: (data: any) => gauge_custom_data(data, 'pipeline', 'Q3'),
    },
  },
  {
    title: 'Q4 Pipeline to Plan',
    key: 'plan-to-goal-q4',
    source: 'financial_plan',
    type: 'gauge',
    size: 2,
    dimensions: ['quarter'],
    capitalize_labels: true,
    metrics: ['goal_pipeline', 'goal', 'last_year_goal', 'last_year_goal_pipeline'],
    filters: {
      quarter: 4,
      type: 'finance_goal',
    },
    config: {
      set_label_to_percentage: true,
      custom_data: (data: any) => gauge_custom_data(data, 'pipeline', 'Q4'),
    },
  },
  {
    title: 'Quarter Overview',
    key: 'quarter-goal',
    source: 'financial_plan',
    type: 'table',
    size: 4,
    dimensions: ['quarter'],
    capitalize_labels: true,
    config: {
      fields: [
        {
          key: 'quarter',
          label: 'Quarter',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
        },
        {
          key: 'goal',
          label: 'Plan',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          type: 'currency_abbreviate',
          sortable: true,
          total: 'currency_abbreviate',
        },
        {
          key: 'goal_pipeline',
          label: 'Pipeline',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          type: 'currency_abbreviate',
          sortable: true,
          total: 'currency_abbreviate',
        },
        {
          key: 'goal_sold',
          label: 'Sold',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          type: 'currency_abbreviate',
          sortable: true,
          total: 'currency_abbreviate',
        },
        {
          key: 'goal_achieved',
          label: 'Invoiced',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          type: 'currency_abbreviate',
          sortable: true,
          total: 'currency_abbreviate',
        },
      ],
    },
    metrics: [
      'goal',
      'goal_pipeline',
      'goal_sold',
      'goal_achieved',
      'last_year_goal',
      'last_year_goal_pipeline',
      'last_year_goal_sold',
      'last_year_goal_achieved',
    ],
    filters: {
      type: 'finance_goal',
    },
  },
  {
    title: 'Region Overview',
    key: 'region-goal',
    source: 'financial_plan',
    type: 'table',
    size: 4,
    dimensions: ['region'],
    capitalize_labels: true,
    config: {
      fields: [
        {
          key: 'region',
          label: 'Region',
          class: 'text-center align-middle',
          show: true,
          type: 'capitalize',
          thClass: 'align-middle text-center',
          sortable: true,
        },
        {
          key: 'goal',
          label: 'Plan',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          type: 'currency_abbreviate',
          sortable: true,
          total: 'currency_abbreviate',
        },
        {
          key: 'goal_pipeline',
          label: 'Pipeline',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          type: 'currency_abbreviate',
          sortable: true,
          total: 'currency_abbreviate',
        },
        {
          key: 'goal_sold',
          label: 'Sold',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          type: 'currency_abbreviate',
          sortable: true,
          total: 'currency_abbreviate',
        },
        {
          key: 'goal_achieved',
          label: 'Invoiced',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          type: 'currency_abbreviate',
          sortable: true,
          total: 'currency_abbreviate',
        },
      ],
    },
    metrics: [
      'goal',
      'goal_pipeline',
      'goal_sold',
      'goal_achieved',
      'last_year_goal',
      'last_year_goal_pipeline',
      'last_year_goal_sold',
      'last_year_goal_achieved',
    ],
    filters: {
      type: 'finance_goal',
    },
  },
  {
    title: 'Weekly Pipeline Overview',
    type: 'custom',
    source: 'opportunity-week-card',
    size: 4,
    config: {
      custom_data: (data: any) => {
        let ret = `<pre>${JSON.stringify(data)}</pre>`

        // Closed
        const closed = numeral(data.closed).format('0,0')
        const last_week_closed = numeral(data.last_week_closed).format('0,0')
        const closed_percentage = numeral(data.last_week_closed_percentage).format('0%')
        const closed_color = data.last_week_closed_percentage > 0 ? 'text-success' : 'text-danger'
        const closed_icon = data.last_week_closed_percentage > 0 ? '▴' : '▾'

        // Closed Amount
        const closed_amount = numeral(data.closed_amount).format('$0,0.00a')
        const last_week_closed_amount = numeral(data.last_week_closed_amount).format('$0,0.00a')
        const closed_amount_percentage = numeral(data.last_week_closed_amount_percentage).format(
          '0%',
        )
        const closed_amount_color = data.last_week_closed_amount_percentage > 0 ? 'text-success' : 'text-danger'
        const closed_amount_icon = data.last_week_closed_amount_percentage > 0 ? '▴' : '▾'

        // New
        const new_count = numeral(data.new).format('0,0')
        const last_week_new = numeral(data.last_week_new).format('0,0')
        const new_percentage = numeral(data.last_week_new_percentage).format('0%')
        const new_color = data.last_week_new_percentage > 0 ? 'text-success' : 'text-danger'
        const new_icon = data.last_week_new_percentage > 0 ? '▴' : '▾'

        // New Ammount
        const new_amount = numeral(data.new_amount).format('$0,0.00a')
        const last_week_new_amount = numeral(data.last_week_new_amount).format('$0,0.00a')
        const new_amount_percentage = numeral(data.last_week_new_amount_percentage).format('0.00%')
        const new_amount_color = data.last_week_new_amount_percentage > 0 ? 'text-success' : 'text-danger'
        const new_amount_icon = data.last_week_new_amount_percentage > 0 ? '▴' : '▾'

        // Lost
        const lost = numeral(data.lost).format('0,0')
        const last_week_lost = numeral(data.last_week_lost).format('0,0')
        const lost_percentage = numeral(data.last_week_lost_percentage).format('0%')
        const lost_color = data.last_week_lost_percentage > 0 ? 'text-success' : 'text-danger'
        const lost_icon = data.last_week_lost_percentage > 0 ? '▴' : '▾'

        // Lost Amount
        const lost_amount = numeral(data.lost_amount).format('$0,0.00a')
        const last_week_lost_amount = numeral(data.last_week_lost_amount).format('$0,0.00a')
        const lost_amount_percentage = numeral(data.last_week_lost_amount_percentage).format(
          '0.00%',
        )
        const lost_amount_color = data.last_week_lost_amount_percentage > 0 ? 'text-success' : 'text-danger'
        const lost_amount_icon = data.last_week_lost_amount_percentage > 0 ? '▴' : '▾'

        return `
        <div class="row text-center mt-5 pt-2">
          <div class="col">
            <h6>Closed/Won</h6>
            <h4>${closed_amount}</h4>
            <p class="${closed_amount_color}">${closed_amount_icon} ${closed_amount_percentage} (Last week: ${last_week_closed_amount})</p>
          </div>
          <div class="col">
            <h6>Added</h6>
            <h4>${new_amount}</h4>
            <p class="${new_amount_color}">${new_amount_icon} ${new_amount_percentage} (Last week: ${last_week_new_amount})</p>
          </div>
          <div class="col">
            <h6>Lost</h6>
            <h4>-${lost_amount}</h4>
            <p class="${lost_amount_color}">${lost_amount_icon} ${lost_amount_percentage} (Last week: -${last_week_lost_amount})</p>
          </div>
        </div>
        <div class="row text-center mt-3">
          <div class="col">
            <h6>Closed Opps</h6>
            <h4>${closed}</h4>
            <p class="${closed_color}">${closed_icon} ${closed_percentage} (Last week: ${last_week_closed})</p>
          </div>
          <div class="col">
            <h6>New Opps</h6>
            <h4>${new_count}</h4>
            <p class="${new_color}">${new_icon} ${new_percentage} (Last week: ${last_week_new})</p>
          </div>
          <div class="col">
            <h6>Lost Opps</h6>
            <h4>-${lost}</h4>
            <p class="${lost_color}">${lost_icon} ${lost_percentage} (Last week: -${last_week_lost})</p>
          </div>`
      },
    },
  },
  {
    title: 'Closed This Week',
    key: 'week-pipeline',
    source: 'week-pipeline',
    type: 'table',
    size: 12,
    capitalize_labels: true,
    config: {
      actions: [
        { title: 'Export Pipeline', event: 'exportPipeline' },
        { title: 'Export Recently Closed Opportunities', event: 'exportOpportunities' },
      ],
      fields: [
        {
          key: 'advertiser',
          label: 'Advertiser',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
        },
        {
          key: 'agency',
          label: 'Agency',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
        },
        {
          key: 'east',
          label: 'East',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          type: 'currency_abbreviate',
          sortable: true,
          total: 'currency_abbreviate',
        },
        {
          key: 'midwest',
          label: 'Midwest',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          type: 'currency_abbreviate',
          sortable: true,
          total: 'currency_abbreviate',
        },
        {
          key: 'west',
          label: 'West',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          type: 'currency_abbreviate',
          sortable: true,
          total: 'currency_abbreviate',
        },
        {
          key: 'national',
          label: 'National',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          type: 'currency_abbreviate',
          sortable: true,
          total: 'currency_abbreviate',
        },
        {
          key: 'total',
          label: 'Total',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          type: 'currency_abbreviate',
          sortable: true,
          total: 'currency_abbreviate',
        },
      ],
    },
    metrics: ['goal', 'goal_pipeline', 'goal_sold', 'goal_achieved'],
    filters: {
      type: 'finance_goal',
    },
  },
  {
    title: 'Opportunity Status Distribution %YEAR%',
    key: 'opportunity-status-distribution',
    source: 'opportunity-advanced',
    type: 'funnel',
    size: 6,
    capitalize_labels: true,
    label: 'Revenue',
    dimensions: ['status'],
    metrics: ['amount'],
    order: {
      percentage: 'asc',
    },
    filters: {},
    status: ['targeting', 'pitched', 'consideration', 'io_sent', 'won'],
  },
  {
    title: 'Invoiced to Plan %YEAR%',
    key: 'revenue-to-goal',
    source: 'financial_plan',
    type: 'radialBar',
    size: 6,
    dimensions: ['quarter'],
    capitalize_labels: true,
    metrics: ['goal_achieved', 'goal'],
    filters: {
      type: 'finance_goal',
    },
  },
  {
    title: 'Agency Distribution %YEAR%',
    key: 'agency-revenue-distribution',
    source: 'opportunity-advanced',
    type: 'treemap',
    size: 12,
    dimensions: ['agency', 'agency_id'],
    metrics: ['amount'],
    filters: {
      dynamic_filter: 'is:pipeline',
    },
    config: {
      click_key: 'agency',
      click_filter_name: 'Agency',
    },
    dynamic_options: {
      target: 'filters',
      sub_target: 'dynamic_filter',
      options: [
        { text: 'Pipeline', value: 'is:pipeline' },
        { text: 'Sold', value: 'is:sold' },
      ],
    },
  },
  {
    title: 'Advertiser Distribution %YEAR%',
    key: 'advertiser-revenue-distribution',
    source: 'opportunity-advanced',
    type: 'treemap',
    size: 12,
    dimensions: ['advertiser', 'advertiser_id'],
    metrics: ['amount'],
    filters: {
      dynamic_filter: 'is:pipeline',
    },
    config: {
      click_key: 'advertiser',
      click_filter_name: 'Advertiser',
    },
    dynamic_options: {
      target: 'filters',
      sub_target: 'dynamic_filter',
      options: [
        { text: 'Pipeline', value: 'is:pipeline' },
        { text: 'Sold', value: 'is:sold' },
      ],
    },
  },
  {
    title: 'Monthly Product Distribution %YEAR%',
    key: 'monthly-product-revenue',
    source: 'opportunity-advanced',
    type: 'bar_stacked',
    size: 12,
    capitalize_labels: false,
    config: {
      date_dimension_format: 'MMMM YYYY',
      click_key: 'product',
      click_filter_name: 'Product',
    },
    dimensions: ['month', 'product'],
    metrics: ['amount'],
    filters: {
      dynamic_filter: 'is:pipeline',
    },
    dynamic_options: {
      target: 'filters',
      sub_target: 'dynamic_filter',
      options: [
        { text: 'Pipeline', value: 'is:pipeline' },
        { text: 'Sold', value: 'is:sold' },
      ],
    },
  },
  {
    title: 'Monthly Region Distribution %YEAR%',
    key: 'monthly-region-revenue',
    source: 'opportunity-advanced',
    type: 'bar_stacked',
    size: 12,
    capitalize_labels: false,
    config: {
      date_dimension_format: 'MMMM YYYY',
      click_key: 'region',
      click_filter_name: 'Region',
    },
    dimensions: ['month', 'region'],
    metrics: ['amount'],
    filters: {
      dynamic_filter: 'is:pipeline',
    },
    dynamic_options: {
      target: 'filters',
      sub_target: 'dynamic_filter',
      options: [
        { text: 'Pipeline', value: 'is:pipeline' },
        { text: 'Sold', value: 'is:sold' },
      ],
    },
  },
  // {
  //   title: 'Monthly Sales Manager Distribution %YEAR%',
  //   key: 'monthly-sales-manager-revenue',
  //   source: 'opportunity-advanced',
  //   type: 'bar_stacked',
  //   size: 12,
  //   capitalize_labels: true,
  //   config: {
  //     date_dimension_format: 'MMMM YYYY',
  //     click_key: 'sm',
  //     click_filter_name: 'Sales Manager',
  //   },
  //   dimensions: ['month', 'sales_management', 'sales_management_id'],
  //   metrics: ['amount'],
  //   filters: {
  //     dynamic_filter: 'is:pipeline',
  //   },
  //   dynamic_options: {
  //     target: 'filters',
  //     sub_target: 'dynamic_filter',
  //     options: [
  //       { text: 'Pipeline', value: 'is:pipeline' },
  //       { text: 'Sold', value: 'is:sold' },
  //     ],
  //   },
  // },
  {
    title: 'Direct vs 3rd Party Distribution %YEAR%',
    key: 'monthly-source-revenue',
    source: 'opportunity-advanced',
    type: 'bar_stacked',
    size: 12,
    capitalize_labels: false,
    config: {
      date_dimension_format: 'MMMM YYYY',
      click_key: 'deal_type',
      click_filter_name: 'Deal Type',
    },
    dimensions: ['month', 'deal_type'],
    metrics: ['amount'],
    filters: {
      dynamic_filter: 'is:pipeline',
    },
    dynamic_options: {
      target: 'filters',
      sub_target: 'dynamic_filter',
      options: [
        { text: 'Pipeline', value: 'is:pipeline' },
        { text: 'Sold', value: 'is:sold' },
      ],
    },
  },
] as any[]
